<!--
SPDX-FileCopyrightText: 2021 SAP SE or an SAP affiliate company and Gardener contributors

SPDX-License-Identifier: Apache-2.0
-->

<template>
  <g>
    <path d="M18.4608948,15.6410896 C20.9772969,13.1246874 20.9772969,9.0447934 18.4608948,6.52839123 C15.9444926,4.01198906 11.8645986,4.01198906 9.34819638,6.52839123" id="Shape"></path>
    <path d="M6.23549801,9.6410896 C3.71909584,12.1574918 3.71909584,16.2373858 6.23549801,18.753788 C8.75190018,21.2701901 12.8317942,21.2701901 15.3481964,18.753788" id="Shape"></path>
    <polygon id="Rectangle" points="16.3481964 7.07352204 22.7806288 0.641089603 24.3481964 2.20865717 17.915764 8.6410896"></polygon>
    <polygon id="Rectangle" points="0.348196384 23.073522 6.78062882 16.6410896 8.34819638 18.2086572 1.91576395 24.6410896"></polygon>
    <polygon id="Rectangle" points="9.34819638 10.1147738 11.8218806 7.6410896 13.3481964 9.16740539 10.8745122 11.6410896"></polygon>
    <polygon id="Rectangle" points="13.3481964 14.1147738 15.8218806 11.6410896 17.3481964 13.1674054 14.8745122 15.6410896"></polygon>
    </g>
</template>

<script>

export default {
  props: {
    color: {
      type: String,
      default: '#FFFFFF'
    }
  }
}
</script>
